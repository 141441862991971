import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from 'axios';


// Course Module
export const CreateNewCourse = createAsyncThunk(
    'page/CreateNewCourse',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/admin/create-course`, val?.newCourse,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetAllCourses = createAsyncThunk(
    'page/GetAllCourses',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            if (val?.search || val?.date || val?.filterValue) {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/search-blog/${val?.page}?type=${val?.type}&name=${val?.search}&date=${val?.date}&status=${val?.filterValue}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else if (val?.type == 'all') {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/get-all-course/${val?.page}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
            else if (val?.type == 'delete') {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/admin/get-deleted-course/${val?.page}`,
                    { headers: headers });
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetCourse = createAsyncThunk(
    'page/GetCourse',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/get-course/${val?.courseId}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const UpdateCourse = createAsyncThunk(
    'page/UpdateCourse',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/admin/edit-course`, val?.updateCourse,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const DeleteCourse = createAsyncThunk(
    'page/DeleteCourse',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVER_URL}/admin/delete-course/${val?.courseId}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const RestoreOrDeleteCourse = createAsyncThunk(
    'page/RestoreOrDeleteCourse',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVER_URL}/admin/restore-or-delete-course/${val?.courseId}?status=${val?.status}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);

export const GetCourseRegisterUsers = createAsyncThunk(
    'page/GetCourseRegisterUsers',
    async (val, { rejectWithValue }) => {
        const headers = {
            'authorization': val?.token,
            'Content-Type': 'application/json',
        }
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/admin/course-registers?courseId=${val?.courseId}&page=${val?.page}`,
                { headers: headers });
            if (response.status === 200 || response.status === 201) {
                return response;
            }
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    },
);