import React, { useReducer, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';

import { Container, Row, Col, Button } from 'react-bootstrap';
import { Collapse } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { IoImages } from "react-icons/io5";

import MainPagetitle from '../../layouts/MainPagetitle';
import CkEditorBlog from '../CkEditor/CkEditorBlog';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { UpdateAdminProfileImg, successMessage, errorMessage } from '../../../Redux/Slice';
import { GetCourse, UpdateCourse, DeleteCourse } from '../../../Redux/Action';

const initialState = true;
const reducer = (state, action) => {
    switch (action.type) {
        case 'collpase0':
            return { ...state, collpase0: !state.collpase0 }
        case 'collpase1':
            return { ...state, collpase1: !state.collpase1 }
        case 'collpase2':
            return { ...state, collpase2: !state.collpase2 }
        case 'collpase3':
            return { ...state, collpase3: !state.collpase3 }
        case 'collpase4':
            return { ...state, collpase4: !state.collpase4 }
        case 'collpase5':
            return { ...state, collpase5: !state.collpase5 }
        case 'collpase6':
            return { ...state, collpase6: !state.collpase6 }
        case 'collpase7':
            return { ...state, collpase7: !state.collpase7 }
        case 'collpase8':
            return { ...state, collpase8: !state.collpase8 }
        case 'collpase9':
            return { ...state, collpase9: !state.collpase9 }
        case 'collpase10':
            return { ...state, collpase10: !state.collpase10 }
        case 'collpase11':
            return { ...state, collpase11: !state.collpase11 }
        case 'collpase12':
            return { ...state, collpase12: !state.collpase12 }
        case 'section1':
            return { ...state, section1: !state.section1 }
        case 'section2':
            return { ...state, section2: !state.section2 }
        case 'section3':
            return { ...state, section3: !state.section3 }
        case 'section4':
            return { ...state, section4: !state.section4 }
        case 'section5':
            return { ...state, section5: !state.section5 }
        case 'section6':
            return { ...state, section6: !state.section6 }
        case 'section7':
            return { ...state, section7: !state.section7 }
        case 'section8':
            return { ...state, section8: !state.section8 }
        case 'section9':
            return { ...state, section9: !state.section9 }
        default:
            return state
    }
}

function ViewOrEditCourse() {
    const [state, dispatchPage] = useReducer(reducer, initialState);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { AdminProfile, Blog, success, errors, Course } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem('admin-auth-token');
    const { id } = useParams();

    console.log(Course);

    const [isEdit, setIsEdit] = useState(false);
    const [imageUpload, setImageUpload] = useState(null);

    const currentUrl = new URL(window.location.href);
    const pathname = currentUrl.pathname;

    const formik = useFormik({
        initialValues: {
            title: '',
            banner_title: '',
            banner_description: '',
            banner_image: '',
            duration: '',
            language: '',
            teachers: '',
            mode: '',
            date: '',
            timings: '',
            fees: '',
            note: '',
            description: '',
            introduction: '',
            slug: '',
            seo_title: '',
            seo_description: '',
            seo_keyword: '',
            publish_on: '',
            status: null
        },
        onSubmit: async (values, type) => {
            let updateCourse = {
                courseId: id,
                coursetitle: values?.title,
                coursedescription: values?.description,
                courseindroduction: values?.introduction,
                bannercoursetitle: values?.banner_title,
                bannercoursedescription: values?.banner_description,
                bannercourseimage: values?.banner_image,
                duration: values?.duration,
                language: values?.language,
                teacher: values?.teachers,
                mode: values?.mode,
                date: values?.date,
                timings: values?.timings,
                fees: values?.fees,
                note: values?.note,
                slug: values?.slug,
                seotitle: values?.seo_title,
                seodescription: values?.seo_description,
                seokeywords: values?.seo_keyword,
                publishedOn: values?.publish_on,
                isPublished: values?.status == 'Published' ? 1 : 0 ,
            }

            dispatch(UpdateCourse({ token, updateCourse }))
        },
        validate: (values) => {
            const errors = {};

            if (!values.title && isEdit) {
                errors.title = "Requried*";
            }
            if (!values.description && isEdit) {
                errors.description = "Required*";
            }
            if (!values.introduction && isEdit) {
                errors.introduction = "Required*";
            }
            if (!values.banner_title && isEdit) {
                errors.banner_title = "Requried*";
            }
            if (!values.banner_description && isEdit) {
                errors.banner_description = "Requried*";
            }
            if (!values.banner_image && isEdit) {
                errors.banner_image = "Requried*";
            }
            if (!values.slug && isEdit) {
                errors.slug = "Required*";
            }
            if (!values.publish_on && isEdit) {
                errors.publish_on = "Required*";
            }
            if (!values.status && isEdit) {
                errors.status = "Required*";
            }

            console.log(errors);
            
            return errors;
        }

    })

    const getDescriptionData = (data) => {
        formik.setFieldValue('description', data)
    }

    const getIntroductionData = (data) => {
        formik.setFieldValue('introduction', data)
    }

    useEffect(() => {
        if (id) {
            dispatch(GetCourse({ token, courseId: id }));
        }

        if (pathname.includes("edit-course")) {
            setIsEdit(true);
        }
        else if (pathname.includes("view-course")) {
            setIsEdit(false);
        }
    }, [dispatch, token, id, pathname])

    useEffect(() => {
        if (Course) {
            formik.setFieldValue('title', Course?.data?.data?.coursetitle);
            formik.setFieldValue('description', Course?.data?.data?.coursedescription);
            formik.setFieldValue('introduction', Course?.data?.data?.courseindroduction);
            formik.setFieldValue('banner_title', Course?.data?.data?.bannercoursetitle);
            formik.setFieldValue('banner_image', Course?.data?.data?.bannercourseimage);
            formik.setFieldValue('banner_description', Course?.data?.data?.bannercoursedescription);
            formik.setFieldValue('duration', Course?.data?.data?.duration);
            formik.setFieldValue('language', Course?.data?.data?.language);
            formik.setFieldValue('teachers', Course?.data?.data?.teacher);
            formik.setFieldValue('mode', Course?.data?.data?.mode);
            formik.setFieldValue('date', Course?.data?.data?.date);
            formik.setFieldValue('timings', Course?.data?.data?.timings);
            formik.setFieldValue('fees', Course?.data?.data?.fees);
            formik.setFieldValue('fees', Course?.data?.data?.fees);
            formik.setFieldValue('slug', Course?.data?.data?.slug);
            formik.setFieldValue('author', Course?.data?.data?.author);
            formik.setFieldValue('seo_title', Course?.data?.data?.seotitle);
            formik.setFieldValue('seo_description', Course?.data?.data?.seodescription);
            formik.setFieldValue('seo_keyword', Course?.data?.data?.seokeywords);
            formik.setFieldValue('publish_on', Course?.data?.data?.publishedOn);
            formik.setFieldValue('status', Course?.data?.data?.isPublished ? "Published" : "Draft");
        }
    }, [Course])

    useEffect(() => {
        if (imageUpload) {
            let ExistFile = null;
            let formData = new FormData();
            formData.append('filefor', 'blog');
            formData.append('file', imageUpload);
            dispatch(UpdateAdminProfileImg({ token, ExistFile, formData }))
        }

        if (success) {
            if (success?.data?.data?.isCourseUpdated) {
                Swal.fire({
                    title: success?.data?.message,
                    icon: "success",
                    iconColor: "#36AA00",
                    confirmButtonColor: "#36AA00",
                    confirmButtonText: "Okay",
                    timer: 5000
                }).then((result) => {
                    dispatch(successMessage(''));
                    dispatch(GetCourse({ token, courseId: id }));
                    navigate(`/view-course/${id}`);
                })
            }
            else if (success?.data?.data?.isCourseRemoved) {
                Swal.fire({
                    title: success?.data?.message,
                    icon: "success",
                    iconColor: "#36AA00",
                    confirmButtonColor: "#36AA00",
                    confirmButtonText: "Okay",
                    timer: 5000
                }).then((result) => {
                    dispatch(successMessage(''));
                    dispatch(GetCourse({ token, courseId: id }));
                    navigate(`/course-list/`);
                })
            }
            else {
                formik.setFieldValue('banner_image', success?.data?.file)
                setImageUpload(null);
            }
        }
        else if (errors) {
            Swal.fire({
                title: errors?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay",
                timer: 5000
            }).then((result) => {
                dispatch(errorMessage(''));
                dispatch(GetCourse({ token, courseId: id }));
            })
        }
    }, [dispatch, id, token, navigate, imageUpload, success, errors])

    const handleDelete = (id) => {
        let courseId = id;
        Swal.fire({
            title: "Are you sure want to Delete your Course?",
            icon: "warning",
            iconColor: "#CA0505",
            showCancelButton: true,
            cancelButtonColor: "#025BFD",
            confirmButtonColor: "#CA0505",
            confirmButtonText: "Delete"
        }).then((result) => {
            if (result?.isConfirmed) {
                dispatch(DeleteCourse({ token, courseId }));
            }
        });
    }

    const handleEdit = (id) => {
        Swal.fire({
            title: "Are you sure want to Edit your Course?",
            icon: "warning",
            iconColor: "#CA0505",
            showCancelButton: true,
            cancelButtonColor: "#025BFD",
            confirmButtonColor: "#CA0505",
            confirmButtonText: "Edit"
        }).then((result) => {
            if (result?.isConfirmed) {
                navigate(`/edit-course/${id}`)
            }
        });
    }


    return (
        <>
            <MainPagetitle pageTitle={isEdit ? "Edit Course" : "View Course"} parentTitle="Home" />

            <Container fluid>
                <Row className='d-flex flex-wrap gap-4 justify-content-center'>
                    <Col lg={11} className="d-flex gap-3 justify-content-end offer-btns">
                        {
                            Blog?.data?.data?.isPublished ?
                                <button
                                    className="unpublish"
                                    onClick={() => {
                                        formik.setFieldValue('status', 'Draft')
                                        // formik.setFieldValue('publish_on', new Date() )
                                        formik.handleSubmit()
                                    }}
                                >Unpublish</button>
                                : <button
                                    className="publish"
                                    onClick={() => {
                                        formik.setFieldValue('status', 'Published')
                                        // formik.setFieldValue('publish_on', new Date() )
                                        formik.handleSubmit()
                                    }}>Publish</button>

                        }
                        {
                            !isEdit ?
                                <button
                                    className="offer-edit"
                                    onClick={() => handleEdit(id)}
                                >
                                    <MdEdit />
                                </button>
                                : null
                        }
                        <button
                            className="offer-delete"
                            onClick={() => handleDelete(id)}
                        >
                            <RiDeleteBin6Fill />
                        </button>
                    </Col>
                    <Col lg={11} className='add-offer-card'>
                        <div className="filter cm-content-box box-primary">
                            <div className="content-title">
                                <div className="cpa">
                                    Blog Title
                                    <span className="text-red">*</span>
                                </div>

                            </div>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <div className="row">
                                        <Col xl={12} sm={12} >
                                            <form>
                                                <div className="mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Title"
                                                        name='title'
                                                        value={formik.values.title}
                                                        onChange={formik.handleChange}
                                                        disabled={!isEdit}
                                                    // onClick={() =>!isEdit ? handleEdit(id) : null}
                                                    />
                                                    <p className={"error__feedback pt-2 mb-0"}>{formik.errors.title}</p>
                                                </div>
                                            </form>
                                        </Col>
                                        {/* <Col xl={2} sm={2}>
                                            <button type="submit" className="btn btn-primary mt-3 mt-sm-0">Clear</button>
                                        </Col> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={11} className='add-offer-card add-blog-card'>
                        <div className="filter cm-content-box box-primary">
                            <div className="content-title">
                                <div className="cpa">
                                    Banner
                                </div>

                            </div>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <div className="row">
                                        <Col xl={12} sm={12} >
                                            <form>
                                                <div className="mb-3">
                                                    <label htmlFor="">Title <span className="text-red">*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Title"
                                                        name='banner_title'
                                                        value={formik.values.banner_title}
                                                        onChange={formik.handleChange}
                                                        disabled={!isEdit}
                                                    />
                                                    <p className={"error__feedback pt-2 mb-0"}>{formik.errors.banner_title}</p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={12} sm={12} >
                                            <form>
                                                <div className="mb-3">
                                                    <label htmlFor="">Image <span className="text-red">*</span></label>
                                                    <div className="dz-default dlab-message upload-img bg-lightOrange">
                                                        <form action="#" className="dropzone astro-file-upload">
                                                            <IoImages className="file-icon" />

                                                            <div className="fallback">
                                                                <input
                                                                    type="file"
                                                                    name="image"
                                                                    disabled={!isEdit}
                                                                    onChange={(e) => {
                                                                        setImageUpload(e.target.files[0])
                                                                    }}
                                                                    multiple
                                                                />
                                                            </div>
                                                            {
                                                                formik?.values?.banner_image ?
                                                                    <div className="upload-img-text">
                                                                        <p className="file-name">{formik?.values?.banner_image}</p>
                                                                    </div>
                                                                    :
                                                                    <div className="upload-img-text">
                                                                        <p>Upload Your Profile Image</p>
                                                                        <p className="sub-note"><span className="text-red">Note:</span> Passport size photo with Plain background) (jpg, png, jpeg only)</p>
                                                                    </div>
                                                            }
                                                        </form>
                                                    </div>
                                                    <p className={"error__feedback pt-2 mb-0"}>{formik.errors.banner_image}</p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={12} sm={12} >
                                            <form>
                                                <div className="mb-3">
                                                    <label htmlFor="">Description <span className="text-red">*</span></label>
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Description"
                                                        name='banner_description'
                                                        value={formik.values.banner_description}
                                                        onChange={formik.handleChange}
                                                        disabled={!isEdit}
                                                    />
                                                    <p className={"error__feedback pt-2 mb-0"}>{formik.errors.banner_description}</p>
                                                </div>
                                            </form>
                                        </Col>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={11} className='add-offer-card add-blog-card'>
                        <div className="filter cm-content-box box-primary">
                            <div className="content-title">
                                <div className="cpa">
                                    Course Details
                                </div>
                            </div>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <div className="row">
                                        <Col xl={6} sm={6} >
                                            <form>
                                                <div className="mb-4">
                                                    <label htmlFor="">Duration</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="duration"
                                                        name='duration'
                                                        value={formik.values.duration}
                                                        onChange={formik.handleChange}
                                                        disabled={!isEdit}
                                                    />
                                                    <p className={"error__feedback"}></p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={6} sm={6} >
                                            <form>
                                                <div className="mb-4">
                                                    <label htmlFor="">Language</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="language"
                                                        name='language'
                                                        value={formik.values.language}
                                                        onChange={formik.handleChange}
                                                        disabled={!isEdit}
                                                    />
                                                    <p className={"error__feedback"}></p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={6} sm={6} >
                                            <form>
                                                <div className="mb-4">
                                                    <label htmlFor="">Teachers</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="teachers"
                                                        name='teachers'
                                                        value={formik.values.teachers}
                                                        onChange={formik.handleChange}
                                                        disabled={!isEdit}
                                                    />
                                                    <p className={"error__feedback"}></p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={6} sm={6} >
                                            <form>
                                                <div className="mb-4">
                                                    <label htmlFor="">Mode</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="mode"
                                                        name='mode'
                                                        value={formik.values.mode}
                                                        onChange={formik.handleChange}
                                                        disabled={!isEdit}
                                                    />
                                                    <p className={"error__feedback"}></p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={6} sm={6} >
                                            <form>
                                                <div className="mb-4">
                                                    <label htmlFor="">Date</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="date"
                                                        name='date'
                                                        value={formik.values.date}
                                                        onChange={formik.handleChange}
                                                        disabled={!isEdit}
                                                    />
                                                    <p className={"error__feedback"}></p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={6} sm={6} >
                                            <form>
                                                <div className="mb-4">
                                                    <label htmlFor="">Timings </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="timings"
                                                        name='timings'
                                                        value={formik.values.timings}
                                                        onChange={formik.handleChange}
                                                        disabled={!isEdit}
                                                    />
                                                    <p className={"error__feedback"}></p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={6} sm={6} >
                                            <form>
                                                <div className="mb-4">
                                                    <label htmlFor="">Fees</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="fees"
                                                        name='fees'
                                                        value={formik.values.fees}
                                                        onChange={formik.handleChange}
                                                        disabled={!isEdit}
                                                    />
                                                    <p className={"error__feedback"}></p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={6} sm={6} >
                                            <form>
                                                <div className="mb-4">
                                                    <label htmlFor="">Note</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="note"
                                                        name='note'
                                                        value={formik.values.note}
                                                        onChange={formik.handleChange}
                                                        disabled={!isEdit}
                                                    />
                                                    <p className={"error__feedback"}></p>
                                                </div>
                                            </form>
                                        </Col>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col lg={11} className='add-offer-card'>
                        <div className="filter cm-content-box box-primary">
                            <div className="content-title">
                                <div className="cpa">
                                    Description
                                    <span className="text-red">*</span>
                                </div>
                            </div>
                            <div className='card h-auto'>
                                <div className='card-body p-2'>
                                    <div className="custom-ekeditor cms-radius add-content-ckeditor mb-3">
                                        <CkEditorBlog
                                            data={formik.values.description}
                                            setData={getDescriptionData}
                                            isDisabled={!isEdit}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={11} className='add-offer-card'>
                        <div className="filter cm-content-box box-primary">
                            <div className="content-title">
                                <div className="cpa">
                                    Course Introduction
                                    <span className="text-red">*</span>
                                </div>
                            </div>
                            <div className='card h-auto'>
                                <div className='card-body p-2'>
                                    <div className="custom-ekeditor cms-radius add-content-ckeditor mb-3">
                                        <CkEditorBlog
                                            data={formik.values.introduction}
                                            setData={getIntroductionData}
                                            isDisabled={!isEdit}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col lg={11} className='add-offer-card'>
                        <div className="filter cm-content-box box-primary">
                            <div className="content-title">
                                <div className="cpa">
                                    Slug
                                    <span className="text-red">*</span>
                                </div>
                            </div>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <div className="row">
                                        <Col xl={12} sm={12} >
                                            <form>
                                                <div className="mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Title"
                                                        name='slug'
                                                        value={formik.values.slug}
                                                        onChange={formik.handleChange}
                                                        disabled={!isEdit}
                                                    />
                                                    <p className={"error__feedback pt-2 mb-0"}>{formik.errors.slug}</p>
                                                </div>
                                            </form>
                                        </Col>
                                        {/* <Col xl={2} sm={2}>
                                            <button type="submit" className="btn btn-primary mt-3 mt-sm-0">Clear</button>
                                        </Col> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col lg={11} className='add-offer-card'>
                        <div className="filter cm-content-box box-primary">
                            <div className="content-title">
                                <div className="cpa">
                                    Seo
                                </div>

                            </div>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <div className="row">
                                        <Col xl={6} sm={6} >
                                            <form>
                                                <div className="mb-3">
                                                    <label htmlFor="">Page Title </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Title"
                                                        name='seo_title'
                                                        value={formik.values.seo_title}
                                                        onChange={formik.handleChange}
                                                        disabled={!isEdit}
                                                    />
                                                    <p className={"error__feedback pt-2 mb-0"}>{formik.errors.seo_title}</p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={6} sm={6} >
                                            <form>
                                                <div className="mb-3">
                                                    <label htmlFor="">Keywords </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Title"
                                                        name='seo_keyword'
                                                        value={formik.values.seo_keyword}
                                                        onChange={formik.handleChange}
                                                        disabled={!isEdit}
                                                    />
                                                    <p className={"error__feedback pt-2 mb-0"}>{formik.errors.seo_keyword}</p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={12} sm={12} >
                                            <form>
                                                <div className="mb-3">
                                                    <label htmlFor="">Description </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Title"
                                                        name='seo_description'
                                                        value={formik.values.seo_description}
                                                        onChange={formik.handleChange}
                                                        disabled={!isEdit}
                                                    />
                                                    <p className={"error__feedback pt-2 mb-0"}>{formik.errors.seo_description}</p>
                                                </div>
                                            </form>
                                        </Col>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={11} className='add-offer-card'>
                        <div className="filter cm-content-box box-primary">
                            <div className={`content-title`}>
                                <div className="cpa">
                                    Published
                                    <span className="text-red">*</span>
                                </div>
                                {/* <div className="tools">
                                    <Link to={"#"}
                                        className={`SlideToolHeader ${state.collpase3 ? 'collapse' : 'expand'}`}
                                        onClick={() => dispatch({ type: 'collpase3' })}
                                    >
                                        <i className="fas fa-angle-up"></i>
                                    </Link>
                                </div> */}
                            </div>
                            <div className="cm-content-body publish-content form excerpt">
                                <div className="card-body pb-0">
                                    <div className="accordion-item">
                                        <ul className="d-flex align-items-center mb-2">
                                            <li><Link to={"#"}><i className="fas fa-key"></i></Link></li>
                                            <li><Link to={"#"} className="ms-2">Status:</Link></li>
                                            <li><strong><Link to={"#"} className="mx-2">{formik.values.status ? formik.values.status : "none"}</Link></strong></li>
                                            <li>
                                                <Link to={"#"} className="accordion accordion-primary"
                                                    onClick={() => dispatchPage({ type: 'collpase7' })}
                                                >
                                                    Edit
                                                </Link>
                                            </li>
                                            <p className={"error__feedback px-3 mb-0"}>{formik.errors.status}</p>
                                        </ul>
                                        <div
                                            className={`collpase ${state.collpase7 ? 'show' : ''}`}
                                            id="headingAccord"
                                        >
                                            <Collapse in={state.collpase7}>
                                                <div className="accordion-body-text border p-3">
                                                    <div className="Cms-selecter mb-2">
                                                        <label className="form-label w-100">Content Type</label>
                                                        {/* <Select
                                                            options={options2}
                                                            className="custom-react-select"
                                                            name='status'
                                                            value={formik.values.status}
                                                            onChange={(item) =>
                                                                formik.setFieldValue('status', item)
                                                            }
                                                            isDisabled={!isEdit}
                                                      
                                                        /> */}
                                                        <select
                                                            className="custom-react-select"
                                                            name='status'
                                                            value={formik.values.status}
                                                            onChange={(e) =>
                                                                formik.setFieldValue('status', e.target.value)
                                                            }
                                                            disabled={!isEdit}
                                                        >
                                                            <option value="Published">Published</option>
                                                            <option value="Approval">Approval</option>
                                                            <option value="Draft">Draft</option>
                                                        </select>
                                                    </div>
                                                    {/* <div>
                                                        <button className="btn btn-primary btn-sm">Ok</button>{" "}
                                                        <button className="btn btn-danger light btn-sm"
                                                            onClick={() => dispatchPage({ type: 'collpase7' })}
                                                        >Cancel</button>
                                                    </div> */}
                                                </div>
                                            </Collapse>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <ul className="d-flex align-items-center mb-2 flex-wrap">
                                            <li><Link to={"#"}><i className="fas fa-calendar-alt"></i></Link></li>
                                            <li><Link to={"#"} className="ms-2">Published</Link></li>
                                            <li><strong><Link to={"#"} className="mx-2">on :{formik.values.publish_on ? formik.values.publish_on : "none"} </Link></strong></li>
                                            <li><Link to={"#"} className="accordion accordion-primary"
                                                onClick={() => dispatchPage({ type: 'collpase9' })}
                                                id="headingthree">Edit
                                            </Link>
                                            </li>
                                            <p className={"error__feedback px-3 mb-0"}>{formik.errors.publish_on}</p>
                                        </ul>
                                        <Collapse in={state.collpase9}>
                                            <div
                                                id="collapsethree"
                                                // className="collapse heading3"
                                                className={`collpase ${state.collpase9 ? 'show' : ''}`}
                                            >
                                                <div className="accordion-body-text border p-3">
                                                    <div className="basic-form mb-2">
                                                        <input
                                                            type="date"
                                                            name="publish_on"
                                                            className=" form-control"
                                                            placeholder="DD/MM/YY"
                                                            value={formik.values.publish_on}
                                                            onChange={formik.handleChange}
                                                            disabled={!isEdit}
                                                        />
                                                    </div>
                                                    {/* <div>
                                                        <button className="btn btn-primary btn-sm">Ok</button>{" "}
                                                        <button className="btn btn-danger light btn-sm"
                                                            onClick={() => dispatchPage({ type: 'collpase9' })}
                                                        >Cancel</button>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </Collapse>
                                    </div>

                                    <div className="accordion-item">
                                        <ul className="d-flex align-items-center mb-2 flex-wrap">
                                            <li><Link to={"#"}><i className="fas fa-calendar-alt"></i></Link></li>
                                            <li><Link to={"#"} className="ms-2">Approved By</Link></li>
                                            <li><strong><Link to={"#"} className="mx-2"> :{Blog?.data?.data?.approvedBy} </Link></strong></li>
                                        </ul>
                                    </div>
                                </div>
                                <hr style={{ margin: "0px" }} className="mx-2" />
                                {
                                    isEdit ?
                                        <div className="card-footer border-0 text-end py-3 ">
                                            <Button
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    formik.handleSubmit()
                                                }}>
                                                Submit
                                            </Button>
                                        </div>
                                        : null
                                }

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ViewOrEditCourse;