import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';

import { Multiselect } from 'multiselect-react-dropdown';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CreatableSelect from 'react-select/creatable';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { UpdateAstrologerProfile, errorMessage } from '../../../Redux/Slice';

const options = [
    { value: 'Axis Bank Ltd', label: 'Axis Bank Ltd' },
    { value: 'Bank of Baroda Ltd', label: 'Bank of Baroda Ltd' },
    { value: 'City Union Bank Ltd', label: 'City Union Bank Ltd' },
    { value: 'Canara Bank', label: 'Canara Bank' },
    { value: 'Central Bank of India', label: 'Central Bank of India' },
    { value: 'HDFC Bank Ltd', label: 'HDFC Bank Ltd' },
    { value: 'ICICI Bank Ltd', label: 'ICICI Bank Ltd' },
    { value: 'IDFC First Bank Ltd', label: 'IDFC First Bank Ltd' },
    { value: 'Indian Bank', label: 'Indian Bank' },
    { value: 'Indian Overseas Bank', label: 'Indian Overseas Bank' },
    { value: 'IDFC First Bank Ltd', label: 'IDFC First Bank Ltd' },
    { value: 'Karur Vysya Bank Ltd', label: 'Karur Vysya Bank Ltd' },
    { value: 'Kotak Mahindra Bank Ltd', label: 'Kotak Mahindra Bank Ltd' },
    { value: 'Punjab & Sind Bank', label: 'Punjab & Sind Bank' },
    { value: 'Punjab National Bank', label: 'Punjab National Bank' },
    { value: 'State Bank of India', label: 'State Bank of India' },
    { value: 'South Indian Bank Ltd', label: 'South Indian Bank Ltd' },
    { value: 'Union Bank of India', label: 'Union Bank of India' },
    { value: 'YES Bank Ltd', label: 'YES Bank Ltd' },
];

const EditAstrologerProfile = ({ show, closeEdit, astrologer }) => {
    const dispatch = useDispatch();
    const { errors } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem('admin-auth-token')

    const [expertiseOption, setExpertiseOption] = useState([{ name: 'Face Reading' }, { name: 'KP' }, { name: 'Life Coach' }, { name: 'Nadi' }, { name: 'Numerology' }, { name: 'Palmistry' }, { name: 'Prashana' }, { name: 'Psychic' }, { name: 'Psychologist' }, { name: 'Tarot' }]);
    const [languageOption, setLanguageOption] = useState([{ name: 'English' }, { name: 'Tamil' }, { name: 'Hindi' }, { "name": 'Telugu' }, { "name": 'Malayalam' }, { "name": 'Kannada' }]);

    const formik = useFormik({
        initialValues: {
            astrologerId: astrologer?.data?.data?.astrologer?.amc_id,
            astrologerRate: astrologer?.data?.data?.astrologer?.myrate,
            astrologerShare: astrologer?.data?.data?.astrologer?.myshare_percentage,
            amcShare: 100 - Number(astrologer?.data?.data?.astrologer?.myshare_percentage),
            seo_title: astrologer?.data?.data?.astrologer?.seo_title,
            seo_description: astrologer?.data?.data?.astrologer?.seo_description,
            seo_keywords: astrologer?.data?.data?.astrologer?.seo_keywords,
            name: astrologer?.data?.data?.astrologer?.name,
            email: astrologer?.data?.data?.astrologer?.email,
            phone: astrologer?.data?.data?.astrologer?.phone,
            dateofbirth: astrologer?.data?.data?.astrologer?.dateofbirth,
            age: astrologer?.data?.data?.astrologer?.age,
            gender: astrologer?.data?.data?.astrologer?.gender,
            about: astrologer?.data?.data?.astrologer?.about,
            experience: astrologer?.data?.data?.astrologer?.experience,
            qualification: astrologer?.data?.data?.astrologer?.qualification,
            profession: astrologer?.data?.data?.astrologer?.profession,
            description: astrologer?.data?.data?.astrologer?.description?.data,
            language: astrologer?.data?.data?.astrologer?.language?.data,
            bank_name: astrologer?.data?.data?.bank?.bank_name ?? '',
            accountholder_name: astrologer?.data?.data?.bank?.accountholder_name ?? '',
            accountnumber: astrologer?.data?.data?.bank?.accountnumber ?? '',
            branch: astrologer?.data?.data?.bank?.branch ?? '',
            IFSCcode: astrologer?.data?.data?.bank?.IFSCcode ?? '',
            pancard: astrologer?.data?.data?.bank?.pancard ?? '',
        },
        onSubmit: async (values) => {

            let description = JSON.stringify({ "data": values?.description });
            let language = JSON.stringify({ "data": values?.language });

            let UpdateDetails = {
                astrologerId: astrologer?.data?.data?.astrologer?.id,
                amc_id: values?.astrologerId,
                astrologer_rate: values?.astrologerRate,
                astrologer_share: values?.astrologerShare,
                amc_share: values?.amcShare,

                seo_title: values?.seo_title,
                seo_description: values?.seo_description,
                seo_keywords: values?.seo_keywords,

                name: values?.name,
                email: values?.email,
                phone: values?.phone,
                dateofbirth: values?.dateofbirth,
                age: values?.age,
                gender: values?.gender,
                about: values?.about,

                experience: values?.experience,
                qualification: values?.qualification,
                profession: values?.profession,
                description: description,
                language: language,

                bank_name: values?.bank_name,
                accountholder_name: values?.accountholder_name,
                accountnumber: values?.accountnumber,
                branch: values?.branch,
                IFSCcode: values?.IFSCcode,
                pancard: values?.pancard,
            }

            dispatch(UpdateAstrologerProfile({ token, UpdateDetails }));
        },
        validate: (values) => {
            const errors = {};
            const formatRegex = /^AMCA[A-Za-z0-9]{4}$/;

            if (!values.astrologerId) {
                errors.astrologerId = "Requried*";
            }
            else if (!formatRegex.test(values.astrologerId)) {
                errors.astrologerId = 'Invalid format. It should be in the format AMCAXXXX.';
            }
            if (!values.astrologerRate) {
                errors.astrologerRate = "Required*";
            }
            if (!values.astrologerShare) {
                errors.astrologerShare = "Required*";
            }
            if (!values.amcShare) {
                errors.amcShare = "Required*";
            } else if (values.astrologerShare + values.amcShare != 100) {
                errors.amcShare = "please provide efficient share percentage*";
            }
            if (!values.name) {
                errors.name = "Requried*";
            }
            if (!values.email) {
                errors.email = "Required*";
            }
            else if (values.email !== "" && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = "Invalid email address";
            }
            if (!values.phone) {
                errors.phone = "Required*";
            }
            if (!values.experience) {
                errors.experience = "Required*";
            }
            if (values.description == null) {
                errors.description = "Required*";
            }
            if (values.language == null) {
                errors.language = "Required*";
            }
            // if (!values.bank_name) {
            //     errors.bank_name = "Required*";
            // }

            return errors;
        }
    })

    useEffect(() => {
        if (errors?.status === 400) {
            Swal.fire({
                title: errors?.data?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay",
                timer: 5000
            }).then((result) => {
                dispatch(errorMessage({ errors: '' }));
            })
        }
    }, [dispatch, errors]);

    return (
        <>
            <Modal
                size='xl'
                show={show}
                onHide={() => closeEdit(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3>Edit Astrologer Profile</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col className='astrologer-details'>
                        <Col lg={12}>
                            <h3>AMC Details</h3>
                        </Col>
                        <Col lg={12} className='d-flex flex-wrap justify-content-between astrologer-form'>
                            <Col lg={4} >
                                <h5 >
                                    Astrologer Id
                                    <span className='text-red'>*</span>
                                    <span className='note'>(ex : AMCAXXXX)</span>
                                </h5>
                                <input
                                    type='text'
                                    name='astrologerId'
                                    id='astrologerId'
                                    value={formik.values.astrologerId}
                                    onChange={formik.handleChange}
                                />
                                <p className={"error__feedback"}>{formik.errors.astrologerId}</p>
                            </Col>
                            <Col lg={4}>
                                <h5 >
                                    Astrologer Rate
                                    <span className='text-red'>*</span>
                                    <span className='note'>(per minute)</span>
                                </h5>
                                <input
                                    type='number'
                                    name='astrologerRate'
                                    id='astrologerRate'
                                    value={formik.values.astrologerRate}
                                    onChange={formik.handleChange}
                                />
                                <p className={"error__feedback"}>{formik.errors.astrologerRate}</p>
                            </Col>
                            <Col lg={4}>
                                <h5 >
                                    Astrologer Share
                                    <span className='text-red'>*</span>
                                    <span className='note'>(%)</span>
                                </h5>
                                <input
                                    type='number'
                                    name='astrologerShare'
                                    id='astrologerShare'
                                    value={formik.values.astrologerShare}
                                    onChange={formik.handleChange}
                                />
                                <p className={"error__feedback"}>{formik.errors.astrologerShare}</p>
                            </Col>
                            <Col lg={4}>
                                <h5 >
                                    AMC Share
                                    <span className='text-red'>*</span>
                                    <span className='note'>(%)</span>
                                </h5>
                                <input
                                    type='number'
                                    name='amcShare'
                                    id='amcShare'
                                    value={formik.values.amcShare}
                                    onChange={formik.handleChange}
                                />
                                <p className={"error__feedback"}>{formik.errors.amcShare}</p>
                            </Col>
                        </Col>
                    </Col>

                    <Col className='astrologer-details seo-details'>
                        <Col lg={12}>
                            <h3>Seo Details</h3>
                        </Col>
                        <Col lg={12} className='d-flex flex-wrap justify-content-between astrologer-form'>
                            <Col lg={12}>
                                <h5> Title </h5>
                                <input
                                    type='text'
                                    name='seo_title'
                                    id='seo_title'
                                    value={formik.values.seo_title}
                                    onChange={formik.handleChange}
                                />
                                <p className={"error__feedback"}></p>
                            </Col>
                            <Col lg={12}>
                                <h5> Description </h5>
                                <textarea
                                    type='text'
                                    name='seo_description'
                                    id='seo_description'
                                    value={formik.values.seo_description}
                                    onChange={formik.handleChange}
                                />
                                <p className={"error__feedback"}></p>
                            </Col>
                            <Col lg={12}>
                                <h5> Keywords </h5>
                                <textarea
                                    type='text'
                                    name='seo_keywords'
                                    id='seo_keywords'
                                    value={formik.values.seo_keywords}
                                    onChange={formik.handleChange}
                                />
                                <p className={"error__feedback"}></p>
                            </Col>
                        </Col>
                    </Col>

                    <Col className='astrologer-details'>
                        <Col lg={12}>
                            <h3>Personal Details</h3>
                        </Col>
                        <Col lg={12} className='d-flex flex-wrap justify-content-between astrologer-form'>
                            <Col lg={4}>
                                <h5 >
                                    Name
                                    <span className='text-red'>*</span>
                                </h5>
                                <input
                                    type='text'
                                    name='name'
                                    id='name'
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                />
                                <p className={"error__feedback"}>{formik.errors.name}</p>
                            </Col>
                            <Col lg={4}>
                                <h5 >
                                    Email
                                    <span className='text-red'>*</span>
                                </h5>
                                <input
                                    type='text'
                                    name='email'
                                    id='email'
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                />
                                <p className={"error__feedback"}>{formik.errors.email}</p>
                            </Col>
                            <Col lg={4}>
                                <h5 >
                                    Phone Number
                                    <span className='text-red'>*</span>
                                </h5>
                                <PhoneInput
                                    country={'in'}
                                    id='phone'
                                    name='phone'
                                    value={formik.values.phone}
                                    onChange={(value) => { formik.setFieldValue('phone', value) }}
                                />
                                <p className={"error__feedback"}>{formik.errors.phone}</p>
                            </Col>
                            <Col lg={4}>
                                <h5> Date Of Birth </h5>

                                <DatePicker
                                    className="px-3"
                                    showIcon
                                    placeholderText="Select Date"
                                    selected={new Date(formik.values.dateofbirth)}
                                    onChange={(value) => { formik.setFieldValue('dateofbirth', value) }}
                                    monthsShown={1}
                                    maxDate={new Date()}
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                                <p className={"error__feedback"}></p>
                            </Col>
                            <Col lg={4}>
                                <h5> Age </h5>
                                <input
                                    type='number'
                                    name='age'
                                    id='age'
                                    value={formik.values.age}
                                    onChange={formik.handleChange}
                                />
                                <p className={"error__feedback"}></p>
                            </Col>
                            <Col lg={4}>
                                <h5> Gender </h5>
                                <select
                                    name="gender"
                                    className="px-2"
                                    defaultValue={formik.values.gender}
                                    value={formik.values.gender}
                                    onChange={formik.handleChange}
                                >
                                    <option value="">Choose</option>
                                    <option value="male"  >Male</option>
                                    <option value="female">Female</option>
                                </select>
                                <p className={"error__feedback"}></p>
                            </Col>
                            {/* <Col lg={6}>
                                <h5 >
                                    Marital Status
                                </h5>
                                <input
                                    type='number'
                                    name='amcShare'
                                    id='amcShare'
                                    value={formik.values.amcShare}
                                    onChange={formik.handleChange}
                                />
                                <p className={"error__feedback"}>{formik.errors.amcShare}</p>
                            </Col> */}
                            <Col lg={12} className='mt-3'>
                                <h5> About </h5>
                                <textarea
                                    type="text"
                                    name="about"
                                    className="p-3"
                                    value={formik.values.about}
                                    onChange={formik.handleChange}
                                />
                            </Col>
                        </Col>
                    </Col>

                    <Col className='astrologer-details'>
                        <Col lg={12}>
                            <h3>Professional Details</h3>
                        </Col>
                        <Col lg={12} className='d-flex flex-wrap justify-content-between astrologer-form'>
                            <Col lg={4}>
                                <h5 >
                                    Experience
                                    <span className='text-red'>*</span>
                                </h5>
                                <input
                                    type='number'
                                    name='experience'
                                    id='experience'
                                    className="px-3"
                                    value={formik.values.experience}
                                    onChange={formik.handleChange}
                                />
                                <p className={"error__feedback"}>{formik.errors.experience}</p>
                            </Col>
                            <Col lg={4}>
                                <h5 >
                                    Qualification
                                </h5>
                                <input
                                    type="text"
                                    name="qualification"
                                    className="px-3"
                                    value={formik.values.qualification}
                                    onChange={formik.handleChange}
                                />
                                <p className={"error__feedback"}></p>
                            </Col>
                            <Col lg={4}>
                                <h5 >
                                    Profession
                                </h5>
                                <input
                                    type="text"
                                    name="profession"
                                    className="px-3"
                                    value={formik.values.profession}
                                    onChange={formik.handleChange}
                                />
                                <p className={"error__feedback"}></p>
                            </Col>
                            <Col lg={6}>
                                <h5 >
                                    Areas of Expertise
                                    <span className='text-red'> *</span>
                                </h5>
                                <Multiselect
                                    options={expertiseOption}
                                    selectedValues={formik.values.description}
                                    onSelect={(value) => {
                                        formik.setFieldValue('description', value)
                                    }}
                                    onRemove={(value) => {
                                        formik.setFieldValue('description', value)
                                    }}
                                    displayValue="name"
                                    placeholder='Select here'
                                />
                                <p className={"error__feedback"}>{formik.errors.expertise}</p>
                            </Col>
                            <Col lg={6}>
                                <h5 >
                                    Languages
                                    <span className='text-red'> *</span>
                                </h5>
                                <Multiselect
                                    options={languageOption}
                                    selectedValues={formik.values.language}
                                    onSelect={(value) => {
                                        formik.setFieldValue('language', value)
                                    }}
                                    onRemove={(value) => {
                                        formik.setFieldValue('language', value)
                                    }}
                                    displayValue="name"
                                    placeholder='Select here'
                                />
                                <p className={"error__feedback"}>{formik.errors.language}</p>
                            </Col>
                        </Col>
                    </Col>
                    <Col className='astrologer-details'>
                        <Col lg={12}>
                            <h3>Bank Details</h3>
                        </Col>
                        <Col lg={12} className='d-flex flex-wrap justify-content-between astrologer-form'>
                            <Col lg={4}>
                                <h5 >
                                    Bank account holder name
                                </h5>
                                <input
                                    name="accountholder_name"
                                    className="px-3"
                                    value={formik.values.accountholder_name}
                                    onChange={formik.handleChange}
                                />
                                <p className={"error__feedback"}></p>
                            </Col>
                            <Col lg={4}>
                                <h5 >
                                    Bank name
                                </h5>
                                <CreatableSelect
                                    isClearable
                                    options={options}
                                    placeholder="Select or Type your Bank"
                                    onChange={(newValue) => formik.setFieldValue('bank_name', newValue)}
                                    // value={formik.values.bank_name}
                                    defaultInputValue={formik.values.bank_name}
                                />
                                <p className={"error__feedback"}></p>
                            </Col>
                            <Col lg={4}>
                                <h5 >
                                    Bank account number
                                </h5>
                                <input
                                    name="accountnumber"
                                    className="px-3"
                                    value={formik.values.accountnumber}
                                    onChange={formik.handleChange}
                                />
                                <p className={"error__feedback"}></p>
                            </Col>
                            <Col lg={4}>
                                <h5 >
                                    Branch Name
                                </h5>
                                <input
                                    name="branch"
                                    className="px-3"
                                    value={formik.values.branch}
                                    onChange={formik.handleChange}
                                />
                                <p className={"error__feedback"}></p>
                            </Col>
                            <Col lg={4}>
                                <h5 >
                                    IFSC Code
                                </h5>
                                <input
                                    name="IFSCcode"
                                    className="px-3"
                                    value={formik.values.IFSCcode}
                                    onChange={formik.handleChange}
                                />
                                <p className={"error__feedback"}></p>
                            </Col>
                            <Col lg={4}>
                                <h5 >
                                    Pan number
                                </h5>
                                <input
                                    name="pancard"
                                    className="px-3"
                                    value={formik.values.pancard}
                                    onChange={formik.handleChange}
                                />
                                <p className={"error__feedback"}></p>
                            </Col>
                        </Col>
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={formik.handleSubmit}
                    >
                        Submit
                    </Button>
                    {/* <Button
                        variant="primary"
                        onClick={formik.handleSubmit}
                    >
                        Back
                    </Button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EditAstrologerProfile;