import React, { useReducer, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import { Container, Row, Col, Button } from 'react-bootstrap';
import { Collapse } from 'react-bootstrap';
import Swal from 'sweetalert2';

import MainPagetitle from '../../layouts/MainPagetitle';
import CkEditorBlog from '../CkEditor/CkEditorBlog';

import { IoImages } from "react-icons/io5";

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { successMessage, errorMessage, UpdateAdminProfileImg } from '../../../Redux/Slice';

import { CreateNewCourse, GetAllCourses } from '../../../Redux/Action';

const initialState = true;
const reducer = (state, action) => {
    switch (action.type) {
        case 'collpase0':
            return { ...state, collpase0: !state.collpase0 }
        case 'collpase1':
            return { ...state, collpase1: !state.collpase1 }
        case 'collpase2':
            return { ...state, collpase2: !state.collpase2 }
        case 'collpase3':
            return { ...state, collpase3: !state.collpase3 }
        case 'collpase4':
            return { ...state, collpase4: !state.collpase4 }
        case 'collpase5':
            return { ...state, collpase5: !state.collpase5 }
        case 'collpase6':
            return { ...state, collpase6: !state.collpase6 }
        case 'collpase7':
            return { ...state, collpase7: !state.collpase7 }
        case 'collpase8':
            return { ...state, collpase8: !state.collpase8 }
        case 'collpase9':
            return { ...state, collpase9: !state.collpase9 }
        case 'collpase10':
            return { ...state, collpase10: !state.collpase10 }
        case 'collpase11':
            return { ...state, collpase11: !state.collpase11 }
        case 'collpase12':
            return { ...state, collpase12: !state.collpase12 }
        case 'section1':
            return { ...state, section1: !state.section1 }
        case 'section2':
            return { ...state, section2: !state.section2 }
        case 'section3':
            return { ...state, section3: !state.section3 }
        case 'section4':
            return { ...state, section4: !state.section4 }
        case 'section5':
            return { ...state, section5: !state.section5 }
        case 'section6':
            return { ...state, section6: !state.section6 }
        case 'section7':
            return { ...state, section7: !state.section7 }
        case 'section8':
            return { ...state, section8: !state.section8 }
        case 'section9':
            return { ...state, section9: !state.section9 }
        default:
            return state
    }
}

function AddCourse() {
    const [state, dispatchPage] = useReducer(reducer, initialState);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { AdminProfile, success, errors } = useSelector((state) => state.Astromind);
    const token = localStorage.getItem('admin-auth-token');

    const [imageUpload, setImageUpload] = useState(null);
    const [imageUploadCount, setImageUploadCount] = useState(0);

    const formik = useFormik({
        initialValues: {
            title: '',
            banner_title: '',
            banner_description: '',
            banner_image: '',
            duration: '',
            language: '',
            teachers : '',
            mode: '',
            date:'',
            timings: '',
            fees: '',
            note: '',
            description: '',
            introduction: '',
            slug: '',
            seo_title: '',
            seo_description: '',
            seo_keyword: '',
            publish_on: '',
            status: null
        },
        onSubmit: async (values) => {
            let newCourse = {
                coursetitle: values?.title,
                coursedescription: values?.description,
                courseindroduction: values?.introduction,
                bannercoursetitle: values?.banner_title,
                bannercoursedescription: values?.banner_description,
                bannercourseimage: values?.banner_image,
                duration: values?.duration,
                language: values?.language,
                teacher: values?.teachers,
                mode: values?.mode,
                date: values?.date,
                timings: values?.timings,
                fees: values?.fees,
                note: values?.note,
                slug: values?.slug,
                seotitle: values?.seo_title,
                seodescription: values?.seo_description,
                seokeywords: values?.seo_keyword,
                publishedOn: values?.publish_on,
                isPublished: values?.status == 'Published' ? 1 : 0,
            }
            console.log(newCourse);
            
            dispatch(CreateNewCourse({ token, newCourse }))
        },
        validate: (values) => {
            const errors = {};
            const slugRegex = /^[a-z](-?[a-z])*$/;

            if (!values.title) {
                errors.title = "Requried*";
            }
            if (!values.banner_title) {
                errors.banner_title = "Requried*";
            }
            if (!values.banner_description) {
                errors.banner_description = "Requried*";
            }
            if (!values.banner_image) {
                errors.banner_image = "Requried*";
            }
            if (!values.description) {
                errors.description = "Required*";
            }
            if (!values.introduction) {
                errors.introduction = "Required*";
            }
            if (!values.slug) {
                errors.slug = "Required*";
            }
            else if (!slugRegex.test(values.slug)) {
                errors.slug = "Slug can only contain lowercase letters and hyphens";
            }
            // if (!values.seo_title) {
            //     errors.seo_title = "Required*";
            // }
            // if (!values.seo_description) {
            //     errors.seo_description = "Required*";
            // }
            // if (!values.seo_keyword) {
            //     errors.seo_keyword = "Required*";
            // }
            if (!values.publish_on) {
                errors.publish_on = "Required*";
            }
            if (!values.status) {
                errors.status = "Required*";
            }

            console.log(errors);
            
            return errors;
        }

    })

    const getDescriptionData = (data) => {
        formik.setFieldValue('description', data)
    }

    const getIntroductionData = (data) => {
        formik.setFieldValue('introduction', data)
    }

    useEffect(() => {
        if (imageUpload && imageUploadCount == 0) {
            let ExistFile = null;
            let formData = new FormData();
            formData.append('filefor', 'blog');
            formData.append('file', imageUpload);
            dispatch(UpdateAdminProfileImg({ token, ExistFile, formData }));
            setImageUploadCount(1);
        }
        if (success) {
            if (success?.data?.data?.isCourseCreated) {
                Swal.fire({
                    title: success?.data?.message,
                    icon: "success",
                    iconColor: "#36AA00",
                    confirmButtonColor: "#36AA00",
                    confirmButtonText: "Okay",
                    timer: 5000
                }).then(() => {
                    dispatch(successMessage(''));
                    dispatch(GetAllCourses({ token, page: 1, type: 'all' }));
                    navigate('/course-list');
                })
            }
            else {
                formik.setFieldValue('banner_image', success?.data?.file)
                setImageUpload(null);
                setImageUploadCount(0);
                dispatch(successMessage(''));
            }

        }
        else if (errors) {
            Swal.fire({
                title: errors?.data?.message || errors?.message,
                icon: "error",
                iconColor: "#CA0505",
                confirmButtonColor: "#CA0505",
                confirmButtonText: "Okay",
                timer: 5000
            }).then(() => {
                dispatch(errorMessage(''));
                setImageUpload(null);
            })
        }
    }, [dispatch, token, navigate, formik, imageUpload, success, errors, imageUploadCount])

    console.log(errors);
    
    return (
        <>
            <MainPagetitle pageTitle="Add Course" parentTitle="Home" />

            <Container fluid>
                <Row className='d-flex flex-wrap gap-4 justify-content-center'>
                    <Col lg={11} className='add-offer-card'>
                        <div className="filter cm-content-box box-primary">
                            <div className="content-title">
                                <div className="cpa">
                                    Course Title
                                    <span className="text-red">*</span>
                                </div>
                            </div>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <div className="row">
                                        <Col xl={12} sm={12} >
                                            <form>
                                                <div className="mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Title"
                                                        name='title'
                                                        value={formik.values.title}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <p className={"error__feedback pt-2 mb-0"}>{formik.errors.title}</p>
                                                </div>
                                            </form>
                                        </Col>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={11} className='add-offer-card add-blog-card'>
                        <div className="filter cm-content-box box-primary">
                            <div className="content-title">
                                <div className="cpa">
                                    Banner
                                </div>
                            </div>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <div className="row">
                                        <Col xl={12} sm={12} >
                                            <form>
                                                <div className="mb-3">
                                                    <label htmlFor="">Title <span className="text-red">*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Title"
                                                        name='banner_title'
                                                        value={formik.values.banner_title}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <p className={"error__feedback pt-2 mb-0"}>{formik.errors.banner_title}</p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={12} sm={12} >
                                            <form>
                                                <div className="mb-3">
                                                    <label htmlFor="">Image <span className="text-red">*</span></label>
                                                    <div className="dz-default dlab-message upload-img bg-lightOrange">
                                                        <form action="#" className="dropzone astro-file-upload">
                                                            <IoImages className="file-icon" />
                                                            <div className="fallback">
                                                                <input
                                                                    type="file"
                                                                    name="image"
                                                                    onChange={(e) => {
                                                                        setImageUpload(e.target.files[0])
                                                                    }}
                                                                    multiple />
                                                            </div>
                                                            {
                                                                formik?.values?.banner_image ?
                                                                    <div className="upload-img-text">
                                                                        <p className="file-name">{formik?.values?.banner_image}</p>
                                                                    </div>
                                                                    :
                                                                    <div className="upload-img-text">
                                                                        <p>Upload Your Profile Image</p>
                                                                        <p className="sub-note"><span className="text-red">Note:</span> Passport size photo with Plain background) (jpg, png, jpeg only)</p>
                                                                    </div>
                                                            }
                                                        </form>
                                                    </div>
                                                    <p className={"error__feedback pt-2 mb-0"}>{formik.errors.banner_image}</p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={12} sm={12} >
                                            <form>
                                                <div className="mb-3">
                                                    <label htmlFor="">Description <span className="text-red">*</span></label>
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Description"
                                                        name='banner_description'
                                                        value={formik.values.banner_description}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <p className={"error__feedback pt-2 mb-0"}>{formik.errors.banner_description}</p>
                                                </div>
                                            </form>
                                        </Col>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col lg={11} className='add-offer-card add-blog-card'>
                        <div className="filter cm-content-box box-primary">
                            <div className="content-title">
                                <div className="cpa">
                                    Course Details
                                </div>
                            </div>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <div className="row">
                                        <Col xl={6} sm={6} >
                                            <form>
                                                <div className="mb-4">
                                                    <label htmlFor="">Duration</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="duration"
                                                        name='duration'
                                                        value={formik.values.duration}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <p className={"error__feedback"}></p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={6} sm={6} >
                                            <form>
                                                <div className="mb-4">
                                                    <label htmlFor="">Language</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="language"
                                                        name='language'
                                                        value={formik.values.language}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <p className={"error__feedback"}></p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={6} sm={6} >
                                            <form>
                                                <div className="mb-4">
                                                    <label htmlFor="">Teachers</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="teachers"
                                                        name='teachers'
                                                        value={formik.values.teachers}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <p className={"error__feedback"}></p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={6} sm={6} >
                                            <form>
                                                <div className="mb-4">
                                                    <label htmlFor="">Mode</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="mode"
                                                        name='mode'
                                                        value={formik.values.mode}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <p className={"error__feedback"}></p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={6} sm={6} >
                                            <form>
                                                <div className="mb-4">
                                                    <label htmlFor="">Date</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="date"
                                                        name='date'
                                                        value={formik.values.date}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <p className={"error__feedback"}></p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={6} sm={6} >
                                            <form>
                                                <div className="mb-4">
                                                    <label htmlFor="">Timings </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="timings"
                                                        name='timings'
                                                        value={formik.values.timings}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <p className={"error__feedback"}></p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={6} sm={6} >
                                            <form>
                                                <div className="mb-4">
                                                    <label htmlFor="">Fees</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="fees"
                                                        name='fees'
                                                        value={formik.values.fees}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <p className={"error__feedback"}></p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={6} sm={6} >
                                            <form>
                                                <div className="mb-4">
                                                    <label htmlFor="">Note</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="note"
                                                        name='note'
                                                        value={formik.values.note}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <p className={"error__feedback"}></p>
                                                </div>
                                            </form>
                                        </Col>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col lg={11} className='add-offer-card'>
                        <div className="filter cm-content-box box-primary">
                            <div className="content-title">
                                <div className="cpa">
                                    Description
                                    <span className="text-red">*</span>
                                </div>
                                <p className={"error__feedback pt-2 mb-0"}>{formik.errors.description}</p>
                            </div>
                            <div className='card h-auto'>
                                <div className='card-body p-2'>
                                    <div className="custom-ekeditor cms-radius add-content-ckeditor mb-3">
                                        <CkEditorBlog setData={getDescriptionData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={11} className='add-offer-card'>
                        <div className="filter cm-content-box box-primary">
                            <div className="content-title">
                                <div className="cpa">
                                    Course Introduction
                                    <span className="text-red">*</span>
                                </div>
                                <p className={"error__feedback pt-2 mb-0"}>{formik.errors.introduction}</p>
                            </div>
                            <div className='card h-auto'>
                                <div className='card-body p-2'>
                                    <div className="custom-ekeditor cms-radius add-content-ckeditor mb-3">
                                        <CkEditorBlog setData={getIntroductionData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={11} className='add-offer-card'>
                        <div className="filter cm-content-box box-primary">
                            <div className="content-title">
                                <div className="cpa">
                                    Slug
                                    <span className="text-red">*</span>
                                </div>
                            </div>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <div className="row">
                                        <Col xl={12} sm={12} >
                                            <form>
                                                <div className="mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Title"
                                                        name='slug'
                                                        value={formik.values.slug}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <p className={"error__feedback pt-2 mb-0"}>{formik.errors.slug}</p>
                                                </div>
                                            </form>
                                        </Col>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={11} className='add-offer-card'>
                        <div className="filter cm-content-box box-primary">
                            <div className="content-title">
                                <div className="cpa">
                                    Seo
                                </div>
                            </div>
                            <div className="cm-content-body form excerpt">
                                <div className="card-body">
                                    <div className="row">
                                        <Col xl={6} sm={6} >
                                            <form>
                                                <div className="mb-3">
                                                    <label htmlFor="">Page Title </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Title"
                                                        name='seo_title'
                                                        value={formik.values.seo_title}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <p className={"error__feedback pt-2 mb-0"}>{formik.errors.seo_title}</p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={6} sm={6} >
                                            <form>
                                                <div className="mb-3">
                                                    <label htmlFor="">Keywords </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Title"
                                                        name='seo_keyword'
                                                        value={formik.values.seo_keyword}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <p className={"error__feedback pt-2 mb-0"}>{formik.errors.seo_keyword}</p>
                                                </div>
                                            </form>
                                        </Col>
                                        <Col xl={12} sm={12} >
                                            <form>
                                                <div className="mb-3">
                                                    <label htmlFor="">Description</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Title"
                                                        name='seo_description'
                                                        value={formik.values.seo_description}
                                                        onChange={formik.handleChange}
                                                    />
                                                    <p className={"error__feedback pt-2 mb-0"}>{formik.errors.seo_description}</p>
                                                </div>
                                            </form>
                                        </Col>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={11} className='add-offer-card'>
                        <div className="filter cm-content-box box-primary">
                            <div className={`content-title`}>
                                <div className="cpa">
                                    Published
                                    <span className="text-red">*</span>
                                </div>
                            </div>
                            <div className="cm-content-body publish-content form excerpt">
                                <div className="card-body pb-0">
                                    <div className="accordion-item">
                                        <ul className="d-flex align-items-center mb-2">
                                            <li><Link to={"#"}><i className="fas fa-key"></i></Link></li>
                                            <li><Link to={"#"} className="ms-2">Status:</Link></li>
                                            <li><strong><Link to={"#"} className="mx-2">{formik.values.status ? formik.values.status : "none"}</Link></strong></li>
                                            <li>
                                                <Link to={"#"} className="accordion accordion-primary"
                                                    onClick={() => dispatchPage({ type: 'collpase7' })}
                                                >
                                                    Edit
                                                </Link>
                                            </li>
                                            <p className={"error__feedback px-3 mb-0"}>{formik.errors.status}</p>
                                        </ul>
                                        <div
                                            className={`collpase ${state.collpase7 ? 'show' : ''}`}
                                            id="headingAccord"
                                        >
                                            <Collapse in={state.collpase7}>
                                                <div className="accordion-body-text border p-3">
                                                    <div className="Cms-selecter mb-2">
                                                        <label className="form-label w-100">Content Type</label>
                                                        <select
                                                            className="custom-react-select"
                                                            name='status'
                                                            value={formik.values.status}
                                                            onChange={(e) =>
                                                                formik.setFieldValue('status', e.target.value)
                                                            }
                                                        >
                                                            {
                                                                AdminProfile?.data?.data?.role?.rolename == "Admin" ?
                                                                    <option value="Published">Published</option>
                                                                    : <option value="Approval">Approval</option>

                                                            }
                                                            <option value="Draft">Draft</option>
                                                        </select>

                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <ul className="d-flex align-items-center mb-2 flex-wrap">
                                            <li><Link to={"#"}><i className="fas fa-calendar-alt"></i></Link></li>
                                            <li><Link to={"#"} className="ms-2">Published</Link></li>
                                            <li><strong><Link to={"#"} className="mx-2">on :{formik.values.publish_on ? formik.values.publish_on : "none"} </Link></strong></li>
                                            <li><Link to={"#"} className="accordion accordion-primary"
                                                onClick={() => dispatchPage({ type: 'collpase9' })}
                                                id="headingthree">Edit
                                            </Link>
                                            </li>
                                            <p className={"error__feedback px-3 mb-0"}>{formik.errors.publish_on}</p>
                                        </ul>
                                        <Collapse in={state.collpase9}>
                                            <div
                                                id="collapsethree"
                                                // className="collapse heading3"
                                                className={`collpase ${state.collpase9 ? 'show' : ''}`}
                                            >
                                                <div className="accordion-body-text border p-3">
                                                    <div className="basic-form mb-2">
                                                        <input
                                                            type="date"
                                                            name="publish_on"
                                                            className=" form-control"
                                                            placeholder="DD/MM/YY"
                                                            value={formik.values.publish_on}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Collapse>
                                    </div>
                                </div>
                                <hr style={{ margin: "0px" }} className="mx-2" />
                                <div className="card-footer border-0 text-end py-3 ">
                                    <Button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            formik.handleSubmit()
                                        }}>
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AddCourse;